section.how-it-works {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-grey-light);

  .how-it-works-content {
    display: flex;
    flex-direction: column;
    width: 90%;

    @media #{$screen-s}, #{$screen-m} {
      width: 100%;
    }

    .section-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 3em 0;

      .section-item {
        display: flex;
        position: relative;
        width: 80%;
        z-index: 5;

        @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
          width: 100%;
        }

        .section-box-title {
          margin-bottom: 1em;
        }

        .section-box-step{
          color: var(--color-green);
          font-weight: $font-weight-bold;
          position: absolute;
          top: -30px;
          left: -15px;
          font-size: 4em;
          z-index: 11;
        }

        .section-box {
          max-width: 700px;
          width: 80%;
          z-index: 10;
          position: relative;

          @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
            width: 100%;
          }

          .section-box-analysis {
            background: var(--color-grey-light);
            border-radius: 20px;
            height: 320px;
            overflow: auto;

            span {
              color: var(--color-midnight);
              padding: 1em 1.5em;
              margin: 0;
              text-align: start;
              display: block;
              line-height: 1.5;
            }
          }

          .section-box-tag-cloud {
            display: flex;
            flex-direction: column;
            margin-top: 2em;
            min-height: 230px;
            overflow: hidden;

            @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
              height: auto;
            }

            .tag-cloud-item {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
              line-height: 2;
              word-spacing: 0;

              span {
                margin: 0 10px;
                color: var(--color-midnight);
                display: flex;
                align-items: center;

                @media #{$screen-l} {
                  padding: 5px 0;
                }

                @media #{$screen-s}, #{$screen-m} {
                  margin: 0 5px;
                }
              }

              .tag-cloud-small {
                font-weight: $font-weight-light;
                font-size: 1em;
              }

              .tag-cloud-normal {
                font-weight: $font-weight-regular;
                font-size: 1.1em;
              }

              .tag-cloud-big {
                font-weight: $font-weight-semi-bold;
                font-size: 1.8em;
              }

              .tag-cloud-extra-big {
                font-weight: $font-weight-bold;
                font-size: 2.3em;
              }
            }
          }
        }

        .section-arrow {
          position: absolute;

          &.arrow-right {
            bottom: -80px;
            right: 0;
          }
          img {
            width: 85%;
            max-width: 250px;

            @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
              width: 70%;
            }
          }
        }
      }

      .section-img {
        position: absolute;

        &.section-img-right {
          right: 0;

          @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
            display: none;
          }
        }

        img {
          max-width: 360px;
          width: 100%;
        }
      }
    }

    .section-container-reverse {
      flex-direction: row-reverse;
      margin: 4em 0;

      @media #{$screen-s}, #{$screen-m} {
        margin: 6em 0;
      }

      .section-item {
        flex-direction: row-reverse;

        .arrow-left {
          bottom: -80px;
          left: 0;
        }
      }

      .section-img {
        &.section-img-left {
          left: 0;

          @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
            display: none;
          }
        }
      }
    }
  }
}
