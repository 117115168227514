section.user-centric {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  z-index: 10;

  .user-centric-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2em 15em;

    img {
      margin-top: 2em;
      height: 350px;
    }
  }

  .user-centric-content {
    display: flex;
    flex-direction: column;
    margin: 0 2em;
    max-width: 600px;
    width: 100%;

    .section-box {
      margin-bottom: 4em;

      .section-box-title {
        height: 2.5em;
      }
    }
  }
}

@media #{$screen-s}, #{$screen-m}, #{$screen-l} {
  section.user-centric {
    flex-direction: column;

    .user-centric-title,
    .user-centric-content,
    .user-centric-content .section-box {
      margin: 0 0 2em;
      max-width: 100%;
      width: 600px;
    }

    .user-centric-title {
      img {
        display: none;
      }
    }
  }
}
