.btn-scroll {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 30;
  border-radius: $border-radius;
  cursor: pointer;
  padding: 5px 5px;

  background-color: var(--color-midnight);
  border: 1px solid var(--color-grey-light);
  color: var(--color-grey-light);
  opacity: 0.6;

  &:hover {
    background-color: var(--color-grey-light);
    border-color: var(--color-midnight);
    color: var(--color-midnight);
  }

  i {
    vertical-align: text-top;
    zoom: 2;
  }
}

@media #{$screen-s} {
  .btn-scroll i {
      zoom: 1.4;
  }
}
