.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  display: flex;
  padding: 0 20px;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-grey);
  box-shadow: $box-shadow;
  color: var(--color-midnight);
  align-items: center;
  z-index: 100;

  .burger-menu {
    display: none;
  }

  .main-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .main-logo {
      height: 50px;

      img {
        height: 100%;
        width: auto;
      }
    }

    .nav {
      display: flex;
      align-items: center;

      .nav-link {
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        margin: 0 20px;
        transition: color 0.3s;

        &:hover {
          color: var(--color-green);
        }
      }
    }
  }
}

.result-header {
  width: 100%;
  padding: 1em;
  text-align: center;
  z-index: 9;
}

/* RESPONSIVE*/
@media #{$screen-s}, #{$screen-m}, #{$screen-l} {
  .main-header {
    .burger-menu {
      display: block;
    }

    .main-nav {
      .nav {
        display: none;
      }
    }
  }
}

@media #{$screen-s} {
  .main-header {
    padding: 0 10px;
    height: $header-height-mobile;

    .main-nav {
      .main-logo {
        height: 40px;
      }
    }
  }
}
