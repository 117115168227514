.navigation {
  .nav-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    i {
      font-size: 2em;
      color: var(--color-midnight);
    }
  }

  .nav-logo {
    height: 45px;
    margin: 20px;

    span {
      cursor: pointer;
    }

    img {
      width: auto;
      height: 100%;
    }
  }

  .nav-items {
    margin-top: 5vh;

    & > li {
      position: relative;
      border-left: 5px solid var(--color-white);
      cursor: pointer;

      span, a {
        position: relative;
        display: flex;
        align-items: center;
        padding: 2.5vh;
        font-size: 1em;
        color: var(--color-midnight);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        text-decoration: none;
      }

      &:hover {
        border-left: 5px solid var(--color-green);

        span, a {
          color: var(--color-green);
        }
      }
    }
  }
}

/* RESPONSIVE */
@media #{$screen-s}, #{$screen-m} {
  .navigation {
    .nav-items {
      & > li {
        span, a {
          padding: 1em;
        }
      }
    }
  }
}

@media #{$screen-s} {
  .navigation {
    .nav-close {
      top: 10px;
      right: 10px;
    }

    .nav-logo {
      height: 30px;
    }
  }
}
