.footer {
  display: block;
  padding: 30px;
  align-items: center;
  text-align: center;

  .link {
    padding: 0 10px;
    line-height: 2em;
    font-size: 0.875em;
    font-weight: $font-weight-semi-bold;
    cursor: pointer;
    color: var(--color-midnight-light);
    text-decoration: none !important;

    &:not(:last-child) {
      border-right: 1px solid var(--color-midnight-light);
    }

    &:hover {
      text-decoration: underline !important;
    }
  }
}
