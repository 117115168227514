$circle-width: 0.0875em;

.percent-circle-container {
  margin: 2em 6em;

  @media #{$screen-s} {
    margin: 2em 0;
  }

  .percent-circle {
    *, *:before, *:after {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }

    position: relative;
    font-size: 15em;
    margin: auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: var(--color-grey-light);
    overflow: hidden;

    &:after {
      position: absolute;
      top: 0.089em;
      left: 0.089em;
      display: block;
      content: " ";
      border-radius: 50%;
      width: 0.824em;
      height: 0.824em;
      background: var(--color-midnight);
    }

    .percent-circle-value {
      position: absolute;
      width: 1 - (2 * $circle-width);
      height: 1 - (2 * $circle-width);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      font-weight: $font-weight-semi-bold;
      color: var(--color-grey-light);
      border-radius: 50%;
      border: $circle-width solid transparent;
      overflow: hidden;

      .percent-circle-icon {
        position: absolute;
        z-index: 1;
        left: 25%;
        top: 35%;
        font-size: .6em;
        opacity: .7;
      }

      .percent-circle-label {
        margin-top: 1.3em;
        z-index: 1;
        color: var(--color-grey);
        font-size: .09em;
        line-height: 1.5;
      }

      .percent-circle-number {
        z-index: 1;
        color: var(--color-grey);
        font-size: .25em;
        font-weight: $font-weight-bold;
        text-shadow: var(--color-black-dark) 1px 1px 20px;
      }
    }

    .percent-circle-track {
      position: absolute;
      width: 1em;
      height: 1em;
      clip: rect(0em, 1em, 1em, 0.5em);

      .percent-circle-trackbar {
        position: absolute;
        border: $circle-width solid var(--color-grey-light);
        width: 1 - (2 * $circle-width);
        height: 1 - (2 * $circle-width);
        clip: rect(0em, 0.5em, 1em, 0em);
        border-radius: 50%;
        transform: rotate(0deg);
      }

      .percent-circle-run {
        @extend .percent-circle-trackbar;
      }
    }

    @for $j from 51 through 100 {
      &.p#{$j} {
        .percent-circle-track {
          clip: rect(auto, auto, auto, auto);

          .percent-circle-run:after {
            transform: rotate(180deg);
          }

          .percent-circle-fixed {
            @extend .percent-circle-trackbar;
            transform: rotate(180deg);
          }
        }
      }
    }

    @for $j from 1 through 100 {
      &.p#{$j} {
        .percent-circle-track {
          .percent-circle-run {
            transform: rotate(calc((360/100*$j) + deg));
          }
        }
      }
    }
  }

  .percent-circle-title {
    margin-top: .5em;
    width: 100%;
    font-size: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-grey-light);
    text-align: center;
  }

  .percent-circle-text {
    margin: 1.5em auto;
    width: 100%;
    max-width: 400px;
    font-size: 1em;
    white-space: initial;
    color: var(--color-grey-light);
    text-align: center;
  }
}
