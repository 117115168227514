.segment-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1em;

  .segment-title {
    text-align: left;
    margin-bottom: .25em;
    text-transform: capitalize;
  }

  .segment-progress {
    display: flex;
    align-items: center;

    .segment-progress-bars {
      position: relative;
      width: 89%;
      height: 20px;
      margin-right: 0.5em;

      @media #{$screen-m} {
        width: 84%;
      }

      @media #{$screen-s} {
        width: 80%;
      }

      .bar {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        border-radius: 12px;

        &.back {
          width: 100%;
          background-color: var(--color-grey-light);
          border: 10px solid var(--color-grey-light);
        }

        &.track {
          width: 66%;
          transition: width 0.2s ease-in-out;
          border: 10px solid var(--color-midnight);
        }
      }
    }

    .segment-progress-value {
      font-size: 1em;
    }
  }
}
