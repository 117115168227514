section.site-centric {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -12em;
  padding-top: 16em;
  position: relative;
  z-index: 9;

  .site-centric-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;

    .site-centric-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20em;
      margin-left: 4em;
      text-transform: uppercase;

      img {
        margin-top: 2em;
        height: 350px;
      }
    }

    .site-centric-segments {
      display: flex;
      flex-direction: column;
      margin-right: 4em;
      max-width: 600px;
      width: 100%;

      .section-box {
        margin-bottom: 4em;

        .section-box-title {
          height: 2.5em;
        }
      }
    }
  }

  .site-centric-content-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4em;
    width: 100%;

    .site-centric-subtitle {
      margin-bottom: 2em;
      font-weight: $font-weight-semi-bold;

      .section-subtile {
        text-transform: uppercase;
      }

      .section-centric-text {
        color: var(--color-white);
      }
    }

    .site-centric-socio-demo {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

@media #{$screen-s}, #{$screen-m}, #{$screen-l} {
  section.site-centric {
    margin-top: -15em;

    .site-centric-content {
      flex-direction: column;
    }

    .site-centric-content,
    .site-centric-content .site-centric-title,
    .site-centric-content .site-centric-segments,
    .site-centric-content .section-box {
      margin: 0 0 2em;
      max-width: 100%;
      width: 600px;
    }

    .site-centric-content {
      .site-centric-title {
        img {
          display: none;
        }
      }
    }
  }
}

@media #{$screen-s}, #{$screen-m} {
  section.site-centric {
    .site-centric-content-centered {
      .site-centric-socio-demo {
        flex-direction: column;
      }
    }
  }
}
