.wrapper {
  display: block;
  position: relative;
  overflow-x: hidden;

  > :nth-child(2) {
    margin: $header-height auto 0 !important;

    @media #{$screen-s} {
      margin-top: $header-height-mobile !important;
    }
  }

  .main-content {
    position: relative;
    margin: auto;
    text-align: center;
    min-height: 85vh;

    &.homepage {
      background-color: var(--color-midnight);
      background: radial-gradient(closest-side, var(--color-midnight-light) 0%, var(--color-midnight) 90%);

      h1, p {
        color: var(--color-grey);
      }
    }
  }
}

section {
  display: flex;
  align-items: inherit;
  justify-content: space-evenly;
  padding: 4em 3em;

  @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
    flex-direction: column;
    padding: 4em 2em;
  }

  &.section-midnight {
    background-color: var(--color-midnight);
    background: radial-gradient(closest-side, var(--color-midnight-light) 0%, var(--color-midnight) 90%);

    .section-title,
    .section-subtitle {
      color: var(--color-grey);
    }
  }

  .section-title {
    color: var(--color-midnight-light);
    font-size: 2.5em;
  }

  .section-subtitle {
    color: var(--color-midnight-light);
    font-size: 2em;
  }

  .section-box {
    padding: 2em;
    color: var(--color-midnight-light);
    background: var(--color-white);
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    &.section-box-small {
      min-height: 400px;

      .section-box-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .section-box-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .content-error {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          height: 240px;
          color: var(--color-grey-dark);
          font-weight: $font-weight-bold;

          .content-error-icon {
            color: var(--color-grey);
            font-size: 15em;

            @media #{$screen-s} {
              font-size: 12em;
            }
          }
        }
      }

      .section-box-img {
        padding-left: 1em;

        i {
          font-size: 10em;
        }
      }
    }

    .section-box-title {
      font-size: 1.5em;
      font-weight: $font-weight-bold;
      letter-spacing: 0.2pt;
    }
  }
}
