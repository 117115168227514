$font-family: Nunito, sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$font-family-analysis: Monospace, serif;

$border-radius: 30px;
$box-shadow: 2px 2px 6px var(--color-grey);
$header-height: 90px;
$header-height-mobile: 70px;

$breakpoint-s: 480px;
$breakpoint-m: 768px;
$breakpoint-l: 960px;

$screen-s: "screen and (max-width: calc(#{$breakpoint-s} - 1px))";
$screen-m: "screen and (min-width: #{$breakpoint-s}) and (max-width: calc(#{$breakpoint-m} - 1px))";
$screen-l: "screen and (min-width: #{$breakpoint-m}) and (max-width: calc(#{$breakpoint-l} - 1px))";
$screen-xl: "screen and (min-width: #{$breakpoint-l})";

:root {
  --color-white: #FFFFFF;

  --color-grey-light: #F8F9FC;
  --color-grey: #E9ECEF;
  --color-grey-dark: #ADB5BD;

  --color-anthracite: #777777;

  --color-black-light: #363636;
  --color-black: #292929;
  --color-black-dark: #1D1D1D;

  --color-midnight-lighter: #8492A6;
  --color-midnight-light: #5A6D90;
  --color-midnight: #202942;
  --color-midnight-dark: #121725;

  --color-green: #6ED216;

  --color-lavender: #E8ECFD;
  --color-lavender-dark: #CCD3F1;

  --color-sand: #FDF7E8;
  --color-sand-dark: #EADDBF;

  --color-coral-lighter: #FEF4F2;
  --color-coral-light: #FDDEDA;
  --color-coral: #F67262;

  --color-lime-light: #DAF6D9;
  --color-lime: #47D343;
  --color-lime-dark: #38A835;

  --color-blue-light: #E3EDF2;
  --color-blue: #5BC0DE;

  --color-yellow-light: #FDE9B4;
  --color-yellow: #FBCA45;

  --color-purple-light: #D2ADF6;
  --color-purple: #A65DEE;

  --color-pink-light: #FBCCEA;
  --color-pink: #F99AD6;

  --color-red: #F93D42;
  --color-red-dark: #C73034;

  --color-orange: #F5A131;
}
