.burger-menu {
  .burger-menu-btn {
    margin: 1em 1em 1em 0;
    color: inherit;
    cursor: pointer;

    i {
      font-size: 2.5em;
    }
  }

  .burger-menu-content {
    position: fixed;
    top: $header-height;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;
    z-index: 20;

    .burger-menu-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      cursor: pointer;
      -webkit-transition: background 0.3s 0.3s;
      transition: background 0.3s 0.3s;
      z-index: 21;
    }

    .burger-menu-panel {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100vw;
      height: 100%;
      width: 230px;
      background: var(--color-white);
      -webkit-transition: -webkit-transform 0.3s 0.3s;
      transition: transform 0.3s 0.3s;
      -webkit-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
      z-index: 22;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &.visible {
    .burger-menu-content {
      visibility: visible;
      -webkit-transition: visibility 0s 0s;
      transition: visibility 0s 0s;

      .burger-menu-overlay {
        background: rgba(0, 0, 0, 0.7);
        -webkit-transition: background 0.3s 0s;
        transition: background 0.3s 0s;
      }

      .burger-menu-panel {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

/* RESPONSIVE */
@media #{$screen-s} {
  .burger-menu .burger-menu-content {
    top: $header-height-mobile;
  }
}
