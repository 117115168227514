section.highlights {
  display: flex;
  flex-direction: column;

  .highlights-content {
    display: flex;
    justify-content: center;

    .section-box {
      width: 30%;
      max-width: 400px;
      margin: 1em 2em;

      .section-box-title {
        height: 2.5em;
      }
    }

    .highlights-img-container {
      display: inline-flex;
      margin-top: 1em;
      height: 10em;
      color: var(--color-green);

      img {
        margin: auto;
        display: inline-flex;
        border-radius: 12px;
        box-shadow: 0 0 6px var(--color-grey-dark);
      }

      i {
        font-size: 10em;
      }
    }

    .highlights-text {
      margin-top: .5em;
      font-size: 1.25em;
      font-weight: $font-weight-regular;
    }
  }
}

@media #{$screen-s}, #{$screen-m}, #{$screen-l} {
  section.highlights {
    .highlights-content {
      flex-direction: column;

      .section-box {
        width: 100%;
        max-width: unset;
        margin: 1em 0;
      }
    }
  }
}
