button.btn {
  border-radius: $border-radius;
  line-height: 1.5;
  letter-spacing: 0.4pt;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;

  i {
    margin: 0 5px;
    vertical-align: text-top;
  }

  &:disabled {
    cursor: initial;
  }
}

button.btn-big {
  padding: 15px 60px;
  font-size: 1em;
}

button.btn-medium {
  padding: 12px 30px;
  font-size: 1em;

  i {
    font-size: 1.286em;
  }
}

button.btn-small {
  padding: 10px 15px;
  font-size: 0.857em;

  i {
    font-size: 1.143em;
  }
}

button.btn-xsmall {
  padding: 5px 15px;
  font-size: 0.714em;

  i {
    font-size: 1em;
  }
}

button.btn-primary-green {
  background-color: var(--color-green);
  border: 1px solid var(--color-green);
  color: var(--color-white);

  &:hover, &.btn-primary-green-hover {
    background-color: var(--color-white);
    color: var(--color-green);
  }

  &:disabled {
    background-color: var(--color-grey) !important;
    border: 1px solid var(--color-grey-dark) !important;
    color: var(--color-grey-dark) !important;
    cursor: initial;
  }
}

@media #{$screen-s} {
  button.btn-big {
    padding: 15px 30px;
  }

  button.btn-medium {
    padding: 12px 20px;
  }
}
