.action-buttons {
  display: flex;
  flex-direction: column;

  .action-buttons-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .btn {
      margin: 1em;
    }
  }
}
