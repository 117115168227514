input[type=text]::-webkit-input-placeholder, /* WebKit browsers */
input[type=text]::-moz-placeholder, /* Mozilla Firefox 4 to 18 */
input[type=text]::-moz-placeholder, /* Mozilla Firefox 19+ */
input[type=text]::-ms-input-placeholder { /* Internet Explorer 10+ */
  padding-left: 0;
}

input[type=text],
textarea {
  display: block;
  width: 100%;
  padding: 1.5em;
  background: var(--color-white);
  border: 1px solid var(--color-midnight);
  box-sizing: border-box;
  border-radius: $border-radius;
  font-size: .875em;
  font-weight: $font-weight-regular;
  color: var(--color-midnight-light);
  resize: none;
  outline: 0;

  &::placeholder {
    color: var(--color-midnight-lighter);
    opacity: .5;
  }
}

textarea {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 0 solid transparent;
    border-top-left-radius: calc($border-radius / 4);
    border-bottom-left-radius: calc($border-radius / 4);
    -webkit-box-shadow: inset 0 0 0 5px var(--color-midnight-light);
    -moz-box-shadow: inset 0 0 0 5px var(--color-midnight-light);
    box-shadow: inset 0 0 0 5px var(--color-midnight-light);
  }

  &::-webkit-scrollbar-track {
    margin-top: $border-radius;
    margin-bottom: $border-radius;
  }
}

.form-container {
  text-align: left;
  width: auto;
  max-width: 700px;
  margin: auto;
  padding: 1em;
  box-sizing: border-box;

  &.form-container-slim {
    .form-row {
      margin-bottom: 1em;
    }

    .btn-group {
      background: var(--color-grey);
      border: 1px solid var(--color-grey-dark);

      button {
        background-color: var(--color-grey) !important;
        border: 1px solid var(--color-grey) !important;

        &.selected {
          background-color: var(--color-midnight) !important;
          border: 1px solid var(--color-grey-dark) !important;
        }
      }
    }

    input[type=text],
    textarea {
      background: var(--color-grey);
      border: 1px solid var(--color-grey-dark);
      color: var(--color-grey-dark);
    }
  }

  .form-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;

    .form-column {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    p {
      text-align: center;
    }
  }

  .error {
    color: var(--color-red) !important;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
  }
}

.btn-group {
  background: var(--color-white);
  border: 1px solid var(--color-midnight);
  border-radius: $border-radius;

  button {
    margin: 5px;
    text-transform: initial;
    background-color: var(--color-white) !important;
    border: 1px solid var(--color-white) !important;
    color: var(--color-grey-dark) !important;

    &.selected {
      background-color: var(--color-midnight) !important;
      border: 1px solid var(--color-grey-dark) !important;
      color: var(--color-grey) !important;
    }
  }
}

.search-bar {
  display: flex;
  flex-direction: row;
  justify-items: center;
  width: 100%;
  position: relative;

  input[type=text] {
    padding: 1em 4em;
  }

  .input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    margin: auto;
    line-height: 2em;
    fill: var(--color-grey-dark);

    &.clean-icon {
      right: 20px;
      cursor: pointer;

      &:hover {
        fill: var(--color-anthracite);
      }
    }

    &.search-icon {
      left: 20px;
    }
  }

  &.search-bar-disabled {
    input[type=text] {
      padding: 1em 2em;
    }

    .input-icon {
      display: none;
    }
  }

}
