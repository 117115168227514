@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: var(--color-white);
  font-size: 16px;

}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

p {
  display: block;
  margin: auto;
  padding: 1em;
  line-height: 1.5;
  font-size: 1em;
  font-weight: $font-weight-regular;
  color: var(--color-midnight-light);
  word-break: break-word;
}

ul {
  list-style: none;
}

button, input {
  outline: 0;
}

h1, h2 {
  font-weight: $font-weight-bold;
  color: var(--color-midnight-light);
}

h1 {
  font-size: 2em;
  padding: 4em .5em 2em;
  letter-spacing: 0.4pt;

  @media #{$screen-s} {
    font-size: 1.5em;
    padding: 2em .5em 2em;
  }
}

h2 {
  font-size: 2em;
  margin-bottom: 1em;
  letter-spacing: 0.2pt;
  text-transform: uppercase;
}
